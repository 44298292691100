import * as React from 'react'

import Page from '../components/Page'
import Container from '../components/Container'
import IndexLayout from '../layouts'
import NavHeader from '../components/products/NavHeader'
import StViewer from '../components/products/st/StViewer'

const SourTangie = ({pathContext: { locale }}) => (
  <IndexLayout locale={locale}>
    <Page>
      <Container>
        <NavHeader productName={'sour tangie'}/>
        <StViewer productName={'SOUR TANGIE'} />
      </Container>
    </Page>
  </IndexLayout>
)

export default SourTangie
